<template>
  <div class="ssc-search">
    <div class="search-input-wrap">
      <v-text-field
        clearable
        filled
        solo
        dense
        height="48"
        hide-details
        flat
        @keydown.enter.native.prevent.stop="doSearch"
        @click:append="doSearch"
        :placeholder="$t('selfScanning.search.placeholder')"
        append-icon="$search"
        v-model="searchValue"
      ></v-text-field>
    </div>
    <ProductListGrid
      v-if="query != ''"
      :query="query"
      :key="key"
      :hidePromoFilter="true"
      mode="search"
      @productsCount="updateCount"
      @searchedText="updateRealSearch"
      hideFilterString="Main Term"
      itemListName="Search"
      class="product-grid-ssc"
    />
    <div class="search-info">
      <span v-if="count">{{ $tc("lists.products", count) }}</span>
    </div>
  </div>
</template>

<style lang="scss">
.ssc-search {
  .search-input-wrap {
    position: fixed;
    z-index: 9;
    margin-top: -12px;
    padding-top: 11px;
    margin-left: -12px;
    width: 100%;
    background-color: var(--v-grey-lighten3);
    padding: 8px 12px;
  }
  .product-grid-ssc {
    padding-top: 65px;
  }
  .search-info {
    position: fixed;
    bottom: 0;
    z-index: 9;
    margin-top: -12px;
    padding-top: 11px;
    margin-left: -12px;
    width: 100%;
    background-color: var(--v-grey-lighten3);
    padding: 8px 12px;
    height: calc(61px + env(safe-area-inset-top)) !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span {
      font-size: 18px;
      font-weight: 600;
    }
  }
}
</style>

<script>
import ProductListGrid from "@/components/selfscanning/ProductListGrid.vue";
import { mapActions } from "vuex";
export default {
  name: "SelfscanningSearch",
  components: {
    ProductListGrid
  },
  props: {},
  data() {
    return {
      searchValue: "",
      key: 1,
      query: "",
      count: null
    };
  },
  methods: {
    ...mapActions({
      setCategory: "category/setCategory",
      resetFilters: "category/resetFilters"
    }),
    doSearch() {
      this.query = this.searchValue;
      this.key++;
      global.EventBus.$emit("resetFilters");
      this.$router.push({
        path: "/searchSSC",
        name: "SelfscanningSearch",
        query: { q: this.searchValue }
      });
    },
    updateCount(value) {
      this.count = value;
    },
    updateRealSearch(value) {
      console.log(value);
    }
  },
  async created() {
    console.log("q", this.$route.query.q);
    if (this.$route.query.q) {
      this.query = this.$route.query.q;
      this.searchValue = this.$route.query.q;
    }
    this.resetFilters();
  }
};
</script>
