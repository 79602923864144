<template>
  <div class="product-grid-ssc">
    <h2 v-html="title" v-if="title"></h2>
    <v-row v-if="pager.totPages > 0">
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="4"
        xl="3"
        v-for="(product, index) in products"
        :key="product.productId"
        class="product-col"
      >
        <ProductSearchCard
          v-bind:product="product"
          :position="initPosition + index"
          @selectProduct="
            $emit('selectProduct', {
              product: product,
              index: initPosition + index
            })
          "
        />
      </v-col>
      <v-col cols="12"
        ><v-btn
          block
          v-intersect="onIntersect"
          :loading="loading"
          @click="loadMore"
          v-if="$vuetify.breakpoint.xs && hasMoreItems"
        >
          <template v-if="hasMoreItems">{{ $t("products.showMore") }}</template>
          <template v-else>{{ $t("products.showAll") }}</template>
        </v-btn>
        <v-pagination
          v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
          :value="pageFilter"
          :page="pager.selPage"
          :length="pager.totPages ? pager.totPages : 0"
          :totalVisible="7"
          @next.stop.prevent="handlePageFilter"
          @prev="handlePageFilter"
          @input="handlePageFilter"
          class="py-6"
        ></v-pagination
      ></v-col>
    </v-row>
    <div v-else-if="loading" class="product-list-grid-skeleton">
      <v-row>
        <v-col
          v-for="index in 12"
          :key="index"
          cols="12"
          sm="6"
          md="6"
          lg="4"
          xl="3"
        >
          <v-skeleton-loader
            type="image, list-item-three-line"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div class="py-8 text-center" v-else>Nessun prodotto trovato</div>
  </div>
</template>
<script>
import ProductSearchCard from "@/components/selfscanning/ProductSearchCard.vue";
import ProductService from "~/service/productService";
import { mapGetters } from "vuex";
export default {
  components: { ProductSearchCard },
  name: "ProductGridSSC",
  props: {
    productList: { type: Array, required: false },
    categoryId: { type: Number, required: false },
    query: { type: String, required: false },
    title: { type: String, required: false },

    initPosition: { type: Number, default: 0 }
  },

  data() {
    return {
      products: [],
      pager: {}
    };
  },
  computed: {
    ...mapGetters({
      promoFilter: "category/promoFilter",
      newFilter: "category/newFilter",
      sortFilter: "category/sortFilter",
      pageFilter: "category/pageFilter"
    }),
    hasMoreItems() {
      if (this.pager) {
        return this.pager.totPages > this.pager.selPage;
      } else {
        return false;
      }
    }
  },
  methods: {
    async fetchProducts(append) {
      this.loading = true;
      let response = await ProductService.search(
        {
          parent_category_id: this.parentCategoryId,
          parent_product_id: this.parentProductId,
          category_id: this.categoryId || null,
          q: this.query,
          barcode: this.barcode,
          page: this.pageFilter,
          page_size: process.env.VUE_APP_PRODUCT_LIST_PAGE_SIZE,
          sort: this.sortFilter,
          promo: this.promoFilter,
          new_product: this.newFilter
        },
        this.facetsFilter
      );
      // Se ho letto il barcode e mi torna un Prodotto, navigo diretto al Dettaglio
      if (this.barcode && response.products.length > 0) {
        this.$router.push({
          name: "Product",
          params: { slug: response.products[0].slug }
        });
      } else if (append) {
        this.products = [...this.products, ...response.products];
      } else {
        this.products = response.products;
      }

      this.key++;

      this.pager = response.page;
      this.facets = response.facets;

      this.$emit("productsCount", this.pager.totItems);
      this.filteredProductsCount = response.page.totItems;
      if (this.pager.searchedText) {
        this.$emit("searchedText", this.pager.searchedText);
      }
      this.loading = false;
    },
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.hasMoreItems) {
        this.$store.dispatch("category/setFilterPage", this.pageFilter + 1);
        this.fetchProducts(true);
      }
    },
    handlePageFilter(page) {
      var _this = this;
      this.$store.dispatch("category/setFilterPage", page);
      //await di this.fetchProduts
      this.fetchProducts();
      //oppure mettere goTo(0) dentro timeout
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    }
  },
  async created() {
    if (this.$vuetify.breakpoint.xsOnly) {
      let page = this.pageFilter;
      for (let i = 1; i <= page; i++) {
        this.$store.dispatch("category/setFilterPage", i);
        await this.fetchProducts(true);
      }
    } else {
      this.fetchProducts();
    }
    global.EventBus.$on("filterChanged", this.fetchProducts);
  },
  beforeDestroy() {
    global.EventBus.$off("filterChanged");
  }
};
</script>
